<template>
  <div class="floor_box">
    <router-link active-class="activeA" to="/home">
      <i class="iconfont icon-shouye"></i>
      <span>首页</span>
    </router-link>
    <router-link active-class="activeA" to="/ai">
      <i class="iconfont icon-zhinengxuexi"></i>
      <span>七宝AI</span>
    </router-link>
    <router-link to=""> </router-link>
    <router-link class="QIBao_box" active-class="QIBao_box_active" to="/treasure">
      <i class="iconfont icon-chaorongheshujuzhongxin"></i>
      <span>AI矩阵</span>
    </router-link>
    <router-link active-class="activeA" to="/shop">
      <i class="iconfont icon-dibudaohanglan-"></i>
      <span>兑换区</span>
    </router-link>
    <router-link active-class="activeA" to="/my" >
      <i class="iconfont icon-wode"></i>
      <span>个人中心</span>
    </router-link>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>
<style lang="less" scoped>
.floor_box {
  width: 100%;
  display: flex;
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100px;
  background-color: #fff;
  z-index: 10000000;
  .QIBao_box {
    width: 120px;
    height: 120px;
    text-align: center;
    position: absolute;
    top: -60px;
    left: 50%;
    margin-left: -60px;
    border: 10px solid #f8f8fa;
    border-radius: 50%;
    background: #fff;
    color: #383838;
    box-shadow: 0 4 10 #bdbdbd;
  }
  .QIBao_box_active {
    width: 120px;
    height: 120px;
    text-align: center;
    position: absolute;
    top: -60px;
    left: 50%;
    margin-left: -60px;
    border: 10px solid #f8f8fa;
    border-radius: 50%;
    background: linear-gradient(
      143.27deg,
      rgba(255, 106, 0, 1) 0%,
      rgba(238, 9, 121, 1) 100%
    );
    color: #fff;
    box-shadow: 0 4 10 #bdbdbd;
  }
  a {
    transition: .35s;
    display: inline-block;
    height: 100px;
    flex: 1;
    font-size: 16px;
    color: #383838;
    box-sizing: border-box;
    padding-top: 10px;
    text-align: center;
    i {
      display: block;
      font-size: 50px;
      margin-bottom: 10px;
      text-align: center;
    }
    span {
      text-align: center;
      display: block;
    }
  }
  .activeA {
    color: rgba(238, 9, 121, 1) !important;
  }
}
</style>

import axios from "axios";
import router from "./router";

const BASE_URL = "";
const service = axios.create({
    baseURL: "https://saveadmin.wxc1997cheshi.top",
    // baseURL: "http://192.168.0.2:8080",
    // baseURL: "http://192.168.18.118:8080",
    timeout: 10000,
});

// // http request 请求拦截器 发送请求前拦截
// service.interceptors.request.use(
//     (config) => {
//         // 这里判断localStorage里面是否存在token，如果有则在请求头里面设置

//         if (localStorage.token) {
//             config.headers.token = localStorage.getItem("token");
//         }
//         return config;
//     },
//     (err) => {
//         return Promise.reject(err);
//     }
// );

// http response 接收拦截器 接收数据前拦截
service.interceptors.response.use(
    (response) => {
        const res = response.data;
        //token 过期
        if (res.msg == "账号未登录") {
            localStorage.removeItem("token");
            router.push({
                path: "/Login",
            });
        }
        return response;
    },
    (error) => {
        console.log("err" + error); // for debug
        // return Promise.reject(error)
    }
);

export default service;

<template>
  <Header v-if="headShow" :isShow="isShow">{{ title }}</Header>
  <Bj />
  <router-view></router-view>
  <Floor />
</template>
<script>
import Floor from "@/components/Floor.vue";
import Header from "@/components/header.vue";
import { useRouter } from "vue-router";
import { watch, ref, onMounted } from "vue";
import Bj from "@/components/bj.vue";
export default {
  components: { Floor, Header, Bj },
  setup() {
    const router = useRouter();
    let isShow = ref(false);
    let headShow = ref(false);
    let title = ref("久升优选");
    watch(
      () => router.currentRoute.value.path,
      (newValue, oldValue) => {
        let path = router.currentRoute.value;
        if (!headShow.value) {
          return false;
        }
        title.value = path.meta.title;
        isShow.value = path.meta.isShow;
      },
      { immediate: true }
    );
    const init = () => {
      var ua = window.navigator.userAgent.toLowerCase();
      // ua.indexOf('micromessenger')为真-微信端，如果为假，就是其他浏览器
      if (ua.indexOf("micromessenger") > -1) {
        headShow.value = false;
      } else {
        headShow.value = true;
      }
    };
    onMounted(() => {
      init();
    });
    return { isShow, title, headShow };
  },
};
</script>
